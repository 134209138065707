@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Poppins", sans-serif;
    background-color: #f0f0f0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.word {
    display: inline-block;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
}

.word:nth-child(1) {
    animation-delay: 0.1s;
}
.word:nth-child(2) {
    animation-delay: 0.2s;
}
.word:nth-child(3) {
    animation-delay: 0.3s;
}
.word:nth-child(4) {
    animation-delay: 0.4s;
}
.word:nth-child(5) {
    animation-delay: 0.5s;
}
